var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mb-4",attrs:{"must-sort":"","mobile-breakpoint":"1080","headers":_vm.headers,"items":_vm.responses,"page":_vm.pageNumber,"items-per-page":25,"server-items-length":_vm.count,"sort-by":_vm.sortCol,"sort-desc":_vm.sortDir === 'desc',"hide-default-footer":!_vm.responses.length,"footer-props":{
    itemsPerPageOptions: [25],
    showCurrentPage: true,
    showFirstLastPage: true,
  }},on:{"update:page":_vm.updatePage,"update:sort-desc":_vm.onChangeSortDirection,"update:sort-by":_vm.onChangeSortColumn},scopedSlots:_vm._u([{key:"item.responseDate",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"date-column"},[_c('span',[_vm._v(_vm._s(item.responseDate))]),_c('router-link',{staticClass:"date-column__name",attrs:{"to":("/patients/" + (item.patientId))}},[_vm._v(" "+_vm._s(item.patientName)+" ")])],1)]}},{key:"item.patientName",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"patient-column"},[_c('router-link',{staticClass:"patient-column__name",attrs:{"to":("/patients/" + (item.patientId))}},[_vm._v(" "+_vm._s(item.patientName)+" ")]),_c('div',{staticClass:"patient-column__id"},[_c('p',{staticClass:"patient-column__id-header"},[_vm._v(" ID ")]),_c('p',[_vm._v(" "+_vm._s(item.clinicPatientCode)+" ")])])],1)]}},{key:"item.responseStatus",fn:function(ref){
  var item = ref.item;
return [_c('StatusIndicatorIcon',{attrs:{"status":item.responseStatus}})]}},{key:"item.linkIcon",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{staticClass:"view-responses mb-3",attrs:{"text":"","color":"tertiary"},on:{"click":function($event){return _vm.viewAllResponses(item)}}},[_vm._v(" View all responses ")]),_c('i',{staticClass:"material-icons-outlined icon",attrs:{"title":"View all responses to this question"},on:{"click":function($event){return _vm.viewAllResponses(item)}}},[_vm._v(" message ")])]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.tableMessage)+" ")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }









import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import { ResponsesExportClicked } from './+state';

export default defineComponent({
  setup() {
    return {
      exportCsv: sink.lazyBroadcast(ResponsesExportClicked()),
    };
  },
});

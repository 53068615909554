

















































































import { ResponsesSortCol, SortDir, StatusIndicatorIcon } from '@/shared';
import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import {
  ResponsesTableSorted,
  ResponsesPageUpdated,
  ViewAllResponsesClicked,
} from './+state';

export default defineComponent({
  components: { StatusIndicatorIcon },
  setup() {
    const sortCol = sink.select('responses.sort-col');
    const sortDir = sink.select('responses.sort-dir');

    const updateSort = (sortInfo: { col: ResponsesSortCol; dir: SortDir }) =>
      sink.broadcast(ResponsesTableSorted(sortInfo));

    const onChangeSortColumn = (col: ResponsesSortCol) => {
      updateSort({ col, dir: sortDir.value });
    };

    const onChangeSortDirection = (isDesc: boolean) => {
      updateSort({ col: sortCol.value, dir: isDesc ? 'desc' : 'asc' });
    };

    const hasMultipleLanguages = sink.select(
      'capabilities.enabledLanguages.hasMultiple',
    ).value;

    const headers = [
      { value: 'responseDate', text: 'Response Date', sortable: true },
      {
        value: 'patientName',
        text: 'Patient',
        sortable: true,
      },
      { value: 'programModule', text: 'Program Module', sortable: true },
      { value: 'question', text: 'Question', sortable: true },
      { value: 'response', text: 'Response', sortable: false },
      { value: 'responseStatus', text: 'Status', sortable: true },
      { value: 'linkIcon', sortable: false },
    ];

    if (hasMultipleLanguages) {
      headers.push({
        value: 'language',
        text: 'Language',
        sortable: false,
      });
    }

    return {
      onChangeSortColumn,
      onChangeSortDirection,
      updatePage: page => {
        sink.broadcast(ResponsesPageUpdated({ page }));
      },
      viewAllResponses: row => {
        const payload = {
          patientId: row.clinicPatientCode,
          question: row.question,
        };
        sink.broadcast(ViewAllResponsesClicked(payload));
      },
      sortCol,
      sortDir,
      responses: sink.select('responses.table'),
      count: sink.select('responses.count'),
      filtersDirty: sink.select('responses.filters-dirty'),
      pageNumber: sink.select('responses.page-number'),
      tableMessage: sink.select('responses.tableMessage'),
      headers,
    };
  },
});


















































































































































import {
  ListPageFilters,
  PatientStatusFilter,
  MultiSelect,
  ColorsCheckbox,
} from '@/shared';
import DatePicker from '@/shared/components/DatePicker.vue';
import sink from '@/sink';
import { InlineLoader, CheckboxInput } from '@/shared';
import { computed, defineComponent } from '@vue/composition-api';
import {
  ResponsesFilterChanged,
  ResponsesFiltersCleared,
  ResponsesFilterSearched,
  ResponsesFiltersRestored,
} from './+state/events';
import { ResponsesFilters } from './models';

export default defineComponent({
  components: {
    CheckboxInput,
    DatePicker,
    InlineLoader,
    ListPageFilters,
    PatientStatusFilter,
    MultiSelect,
    ColorsCheckbox,
  },
  setup() {
    const filters = sink.select('responses.filters');
    sink.broadcast(ResponsesFiltersRestored());

    return {
      filters,
      loadingPrograms: sink.select('programs.loading'),
      loadingProviders: sink.select('providers.loading'),
      loadingResponses: sink.select('responses.loading'),
      loadingCapabilities: sink.select('capabilities.loading'),
      programs: sink.select('programs.select-options.all'),
      providers: sink.select('providers.select-options'),
      caregiverEnabled: sink.select('capabilities.features.caregiver'),
      hasMultipleLanguages: sink.select(
        'capabilities.enabledLanguages.hasMultiple',
      ),
      languageItems: sink.select('capabilities.enabledLanguages.selectOptions'),
      isDateRangeValid: computed(() => {
        if (filters.value.responsesDateFrom && filters.value.responsesDateTo) {
          return (
            new Date(filters.value.responsesDateTo) >=
              new Date(filters.value.responsesDateFrom) ||
            'Response Date From must be on/before Response Date To'
          );
        }
        return true;
      }),
      onSearch: sink.lazyBroadcast(
        ResponsesFilterSearched({ origin: 'search', resetPage: true }),
      ),
      onClearAll: sink.lazyBroadcast(ResponsesFiltersCleared()),
      onInputChanged(input: keyof ResponsesFilters, value) {
        sink.broadcast(ResponsesFilterChanged({ input, value }));
      },
    };
  },
});
